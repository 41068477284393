<template>
    <div class=" w-full">
        <div class=" px-4 hidden md:flex items-center">
            <div  class=" mr-4">
                <a class="m-0 p-0 items-center flex cursor-pointer flex-wrap" @click="onHomeClick">
                    <feather-icon icon="HomeIcon" svgClasses="h-6 w-6" class="ml-1 mb-1 text-primary2" />
                </a>
            </div>

            <div class=" mr-3" ref="productDropdownContainer">
                <MultiLevelMenu
                    :isMenuOpen="isProductActive"
                    title="Products"
                    :items="navigationSettings.product"
                    @itemClick="redirectToCategory"
                    @menuClick="handleProductMenuClick"
                />
            </div>


            <div class=" mr-3" ref="brandDropdownContainer">
                <BrandDropdown
                    ref="dropdownContainer"
                    title="Brands"
                    :brandData="navigationSettings.brands"
                    @itemClick="redirectToBrand"
                    :isMenuOpen="isBrandActive"
                    @menuClick="handleBrandMenuClick"
                />
            </div>

            <div
                class=" text-primary2 font-medium menuTitle py-1 px-2 rounded-lg cursor-pointer text-lg"
                @click="redirectToPromotions"
                v-if="!isUSRegion"
            >
                Promotions
            </div>
        </div>

        <!-- Mobile -->
        <div class="p-1 pl-0 md:p-5 flex md:hidden">
            <span class="mr-3 font-medium menuTitle rounded-lg cursor-pointer text-lg leading-normal text-black">
                <a class="m-0 p-0 items-center flex cursor-pointer flex-wrap" @click="onHomeClick">
                    <feather-icon icon="HomeIcon" svgClasses="h-6 w-6" class="ml-1 mb-1 text-primary2" />
                </a>
            </span>
            <span class="mr-3 font-medium menuTitle rounded-lg cursor-pointer text-lg leading-normal text-black" @click="productPopupActive=true">Products</span>
            <span class="mr-3 font-medium menuTitle rounded-lg cursor-pointer text-lg leading-normal text-black" @click="brandPopupActive=true">Brands</span>
            <span v-if="!isUSRegion" class="mr-3 font-medium menuTitle rounded-lg cursor-pointer text-lg leading-normal text-black" @click="redirectToPromotions">Promotions</span>
        </div>

        <vs-popup class="learnMorePopup" :fullscreen="true"  :active.sync="productPopupActive" :button-close-hidden="true" title="">
            <div>
                <div>
                    <!-- product header -->
                    <div class=" bg-primary2 text-white flex justify-between p-3">
                        <div class=" cursor-pointer mr-5"></div>
                        <div class=" cursor-pointer">Categories</div>
                        <div class=" cursor-pointer" @click="clearPopup">
                            <vs-icon icon="close" size="small" class=" mr-4 cursor-pointer"></vs-icon>
                        </div>
                    </div>

                    <!-- product list -->
                    <div v-if="step === 1">
                        <vs-list>
                            <div
                                v-for="product, index in navigationSettings.product"
                                :key="index"
                                @click="handleParentClick(product, index)"
                            >
                                <vs-list-item
                                    :title="product.name"
                                    class=" text-primary2 cursor-pointer"
                                >
                                    <vs-icon icon="arrow_forward_ios" size="small" class=" mr-4 cursor-pointer"></vs-icon>
                            </vs-list-item>
                        </div>
                        </vs-list>
                    </div>
                    <div v-if="step === 2">
                        <vs-list>
                            <div
                                v-for="items, index in navigationSettings.product[selectedProductIndex].categories"
                                @click="handleSecondLevelClick(items, index)"
                                :key="index"
                                >
                                <!-- @click="redirectToCategory(items.slug)" -->
                                <vs-list-item
                                    :title="items.name"
                                    class=" text-primary2 cursor-pointer"
                                >
                                    <vs-icon icon="arrow_forward_ios" size="small" class=" mr-4 cursor-pointer" ></vs-icon>
                                </vs-list-item>
                            </div>

                            <div
                                @click="redirectToCategory(navigationSettings.product[selectedProductIndex].slug)"

                                :key="navigationSettings.product[selectedProductIndex].categories.length"
                            >
                                <vs-list-item
                                    class="text-primary2 cursor-pointer"
                                    :title="`SHOP ALL ${navigationSettings.product[selectedProductIndex].name.toUpperCase()}`"
                                >

                                    <vs-icon icon="arrow_forward_ios" size="small" class=" mr-4 cursor-pointer" ></vs-icon>
                                </vs-list-item>
                            </div>
                        </vs-list>
                    </div>
                    <div v-if="step === 3">
                        <vs-list>
                            <div
                                v-for="items, index in navigationSettings.product[selectedProductIndex].categories[selectedSecondProductIndex].categories"
                                @click="redirectToCategory(items.slug)"
                                :key="index"
                            >
                                <vs-list-item
                                    :title="items.name"
                                    class=" text-primary2 cursor-pointer"
                                >
                                    <vs-icon icon="arrow_forward_ios" size="small" class=" mr-4 cursor-pointer" ></vs-icon>
                                </vs-list-item>
                            </div>
                            <div
                                @click="redirectToCategory(navigationSettings.product[selectedProductIndex].categories[selectedSecondProductIndex].slug)"
                                :key="navigationSettings.product[selectedProductIndex].categories[selectedSecondProductIndex].length"
                            >
                                <vs-list-item
                                    class="text-primary2 cursor-pointer"
                                    :title="`SHOP ALL ${navigationSettings.product[selectedProductIndex].categories[selectedSecondProductIndex].name.toUpperCase()}`"
                                >

                                    <vs-icon icon="arrow_forward_ios" size="small" class=" mr-4 cursor-pointer" ></vs-icon>
                                </vs-list-item>
                            </div>
                        </vs-list>
                    </div>
                </div>
            </div>
        </vs-popup>
        <vs-popup class="learnMorePopup" :fullscreen="true"  :active.sync="brandPopupActive" :button-close-hidden="true" title="">
            <div>
                <div>
                    <!-- product header -->
                    <div class=" bg-primary2 text-white flex justify-between p-3">
                        <div class=" cursor-pointer mr-5"></div>
                        <div class=" cursor-pointer">Brands</div>
                        <div class=" cursor-pointer" @click="brandPopupActive=false">
                            <vs-icon icon="close" size="small" class=" mr-4 cursor-pointer"></vs-icon>
                        </div>
                    </div>

                    <!-- brand list -->
                    <div class=" p-5">
                        <!-- <h1>Brands</h1> -->
                        <div class=" my-5 px-3">
                            <span class="text-primary2 font-medium">Popular Brands</span>
                            <div class="grid grid-cols-2 justify-items-center gap-2">
                                <div
                                    v-for="i in navigationSettings.brands.featured_brands"
                                    :key="i"
                                    class="brand-card cursor-pointer hover:bg-primary3 w-full flex items-center justify-center"
                                    @click="redirectToBrand(i.slug)"
                                >
                                    <img
                                        class=" w-24 object-contain"
                                        style="height: 70px"
                                        :src="i.image"
                                        alt="brand_image"
                                    />
                                </div>
                            </div>
                        </div>
                        <div class=" my-5">
                            <span class="text-primary2 font-medium">Anti Wrinkle Brands</span>
                            <div class=" ">
                                <div
                                  class=" text-primary2 hover:underline font-normal category-items pr-5"
                                  @click="redirectToBrand(item.slug)"
                                  v-for="item, index in navigationSettings.brands.antiwrinkle_brands"
                                  :key="index"
                                >
                                    {{ item.name }}
                                </div>
                            </div>
                        </div>
                        <div class=" my-5">
                            <span class="text-primary2 font-medium">Filler Brands</span>
                            <div class="">
                                <div
                                    class=" text-primary2 hover:underline font-normal category-items pr-5"
                                    @click="redirectToBrand(item.slug)"
                                    v-for="item, index in navigationSettings.brands.filler_brands"
                                    :key="index"
                                >
                                    {{ item.name }}
                                </div>
                            </div>
                        </div>
                        <div class=" my-5">
                            <span class="text-primary2 font-medium">Brand A - Z</span>
                            <div class="">
                                <div
                                    class=" text-primary2 hover:underline font-normal category-items pr-5"
                                    v-for="item, index in navigationSettings.brands.all_brands"
                                    :key="index"
                                    @click="redirectToBrand(item.slug)"
                                    style="font-size: 14px"
                                >
                                    {{item.name}}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </vs-popup>
    </div>
</template>

<script>
import { mapActions } from "vuex";
import MultiLevelMenu from './DropdownItem.vue';
import BrandDropdown from './brandsDropdown.vue';
import { isUndefined  } from 'lodash'


export default {
    components: {
        MultiLevelMenu,
        BrandDropdown
    },
    data: () => ({
        navigationSettings: {
          product: [],
          brands: {}
        },
        productPopupActive: false,
        brandPopupActive: false,
        step: 1,
        selectedProductIndex: -1,
        selectedSecondProductIndex: -1,
        isProductActive: false,
        isBrandActive: false
    }),
    methods: {
        ...mapActions("eCommerce", ["getJsonSettings"]),
        async getNavSettings() {
            try{
                const res = await this.getJsonSettings({ slug: "navigation" })
                this.navigationSettings = res.data.data
            }
            catch(err){
                console.log(err)
            }
        },
        onHomeClick() {
            let owner = this.$route.path.split('/')[1]
            const userType = this.$store.state.AppActiveUser.userType
            if (userType === "superAdmin") owner = 'super-admin/store'

            this.$router.push(`/${owner}/shop`)
        },
        handleProductMenuClick(){
            if(this.isProductActive){
                this.isProductActive = false
            }
            else {
                this.isProductActive = true
            }
        },
        handleBrandMenuClick(){
            if(this.isBrandActive){
                this.isBrandActive = false
            }
            else {
                this.isBrandActive = true
            }
        },
        handleParentClick(product, index){
            if(product.categories.length > 0){
                this.step = 2
                this.selectedProductIndex = index
            }
            else {
                this.redirectToCategory(product.slug)
            }
        },
        handleSecondLevelClick(product, index) {
            if(product.categories.length > 0){
                this.step = 3
                this.selectedSecondProductIndex = index
            }
            else {
                this.redirectToCategory(product.slug)
            }
        },
        redirectToCategory(slug = '') {
            console.log(slug, "slug")
            this.isProductActive = false;
            if(slug !== '' || !isUndefined(slug) ){
                const userType = JSON.parse(
                    localStorage.getItem(`${process.env.VUE_APP_ACCESS_TOKEN_NAME}User`)
                ).userType;
                const role = localStorage.getItem(
                    `${process.env.VUE_APP_ACCESS_TOKEN_NAME}Role`
                );

                let routerName = "";
                if (role === "Org Owner") {
                    routerName = "OrgOwnerOrderCenterCategoryPage"
                }
                else {
                    if (userType === "doctor") {
                        routerName = "DoctorOrderCenterCategoryPage"
                    }
                    if (userType === "nurse") {
                        routerName = "NurseOrderCenterCategoryPage";
                    }
                    if (userType === "superAdmin") {
                        routerName = "SuperAdminOrderCenterCategoryPage";
                    }
                    if (userType === "basic") {
                        routerName = "BasicOrderCenterCategoryPage";
                    }
                }

                this.$router.push({
                    name: routerName,
                    params: {
                        slug: slug
                    },
                    query: {
                        page: 1
                    }
                })

                this.clearPopup();
            }
        },
        redirectToBrand(slug = ''){
            this.isBrandActive = false;
            this.brandPopupActive = false
            if(slug !== ''){
                const userType = JSON.parse(
                    localStorage.getItem(`${process.env.VUE_APP_ACCESS_TOKEN_NAME}User`)
                ).userType;
                const role = localStorage.getItem(
                    `${process.env.VUE_APP_ACCESS_TOKEN_NAME}Role`
                );

                let routerName = "";
                if (role === "Org Owner") {
                    routerName = "OrgOwnerOrderCenterBrandPage";
                } else {
                    if (userType === "doctor") {
                        routerName = "DoctorOrderCenterBrandPage";
                    } if (userType === "nurse") {
                        routerName = "NurseOrderCenterBrandPage";
                    }
                    if (userType === "superAdmin") {
                        routerName = "SuperAdminOrderCenterBrandPage";
                    }
                    if (userType === "basic") {
                        routerName = "BasicOrderCenterBrandPage";
                    }
                }
                this.$router.push({
                    name: routerName,
                    params: {
                        slug: slug
                    },
                    query: {
                        page: 1
                    }
                });
            }
        },
        redirectToPromotions(){
            this.isBrandActive = false;
            this.brandPopupActive = false
                const userType = JSON.parse(
                    localStorage.getItem(`${process.env.VUE_APP_ACCESS_TOKEN_NAME}User`)
                ).userType;
                const role = localStorage.getItem(
                    `${process.env.VUE_APP_ACCESS_TOKEN_NAME}Role`
                );

                let routerName = "";
                if (role === "Org Owner") {
                    routerName = "OrgOwnerOrderCenterPromotionsPage";
                } else {
                    if (userType === "doctor") {
                        routerName = "DoctorOrderCenterPromotionsPage";
                    } if (userType === "nurse") {
                        routerName = "NurseOrderCenterPromotionsPage";
                    }
                    if (userType === "superAdmin") {
                        routerName = "SuperAdminPromotionsPage";
                    }if (userType === "basic") {
                        routerName = "BasicOrderCenterPromotionsPage";
                    }
                }
                this.$router.push({
                    name: routerName});
        },
        clearPopup(){
            this.productPopupActive = false,
            this.step = 1,
            this.selectedProductIndex= -1
        },
        handleClickOutside(event, dropdownRef) {
            const dropdownContainer = this.$refs[dropdownRef];
            if (dropdownContainer && !dropdownContainer.contains(event.target)) {
                if (dropdownRef === "productDropdownContainer") {
                    this.isProductActive = false;
                } else if (dropdownRef === "brandDropdownContainer") {
                    this.isBrandActive = false;
                }
            }
        },
        scrollFunction() {
      const deliverTo = document.getElementById("deliver_to")
      const alertBanner = document.getElementById("alert_banner_mobile")
      if (this.lastScrollPosition >= 55) {
        deliverTo.style.top =  "0px";
        alertBanner.style.top = "0px"
      } else if(this.lastScrollPosition <= 55) {
        deliverTo.style.top = "95px";
        alertBanner.style.top = "133px"

      }

      // Update the last scroll position
      this.lastScrollPosition = window.scrollY;
    },
  },
    mounted() {
        window.onscroll = this.scrollFunction;

        document.addEventListener('click', (event) => this.handleClickOutside(event, "productDropdownContainer"));
        document.addEventListener('click', (event) => this.handleClickOutside(event, "brandDropdownContainer"));
    },

    beforeDestroy() {
        window.onscroll = null;
        document.removeEventListener('click', (event) => this.handleClickOutside(event, "productDropdownContainer"));
        document.removeEventListener('click', (event) => this.handleClickOutside(event, "brandDropdownContainer"));
    },
    computed: {
        isUSRegion() {
            return process.env.VUE_APP_REGION === "US";

        },
    },
    created() {
        this.getNavSettings();
    }
}
</script>

<style>

.product-dropdown-position {
    left: 218px !important;
}
.brand-dropdown-position{
    /* left:  183.75px  !important; */
    width: 1000px;
}
.services-dropdown-position{
    left:  343.75px  !important;
}
.promotions-dropdown-position{
    left:  409.75px  !important;
}

@media (min-width: 1200px) {
        .product-dropdown-position {
            left: 481px !important;
        }
        .brand-dropdown-position {
            left: 1291.75px !important;
        }
        .services-dropdown-position {
            left: 606px !important;
        }
        .promotions-dropdown-position {
            left: 668px !important;
        }
}
.brand-card {
  width: 150px;
  height: 150px;
  flex-shrink: 0;
  border: 1px solid #E5E7EB !important;
}

.brand-card:hover img {
  filter: invert(100%);
}

.menu-height{
  min-width: 100%;
  writing-mode: vertical-lr;
  max-height: 80vh;
  @media (min-width: 768px) {
    height: 570px;
  }
  @media (min-width: 900px) {
    height: 550px;
  }
  @media (min-width: 1400px) {
    height: 510px;
  }
}

.category-items {
  min-width: 190px;
  line-height: 2rem;
  margin-right: 20px;
  cursor: pointer;
  writing-mode: horizontal-tb;
}

.con-vs-popup.fullscreen .vs-popup {
    max-width: 100%;
    max-height: 100%;
    margin: 0;
}
[dir] .vs-popup--title h3 {
    display: none !important;
}

[dir] .con-vs-popup .vs-popup--content {
    padding: 0px !important;
    margin: 0px !important;
}

.vs-popup--content {
    max-height: 100vh !important;
}

.vs-list--title{
    font-weight: 400 !important;
}

.menuTitle:hover {
  background-color: #F3FAF8;
}
</style>
